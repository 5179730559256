import React from 'react';
import About from '../../assets/img/about.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();
const AboutDescription = () => {
  return (
    <section className='text-gray-700 body-font' data-aos='zoom-in'>
      <div className='container mx-auto flex px-5 py-24 md:flex-row flex-col items-center'>
        <div className='lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center'>
          <h1 className='title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900'>
            Building the Right Blockchain Solutions
          </h1>
          <p className='mb-8 leading-relaxed'>
            Consenso Labs was started as a research lab to bring in futurist
            programmers and visionaries to explore the decentralization and
            other accompanying technologies to solve some of the fundamental
            problems.
          </p>
        </div>
        <div className='lg:max-w-lg lg:w-full md:w-1/2 w-5/6'>
          <img
            className='object-cover object-center rounded'
            alt='hero'
            src={About}
          />
        </div>
      </div>
    </section>
  );
};

export default AboutDescription;
